import React from 'react';

const QueueList = ({ queues }) => {
  const buildTree = (relations) => {
    const map = {};
    const roots = [];

    relations.forEach(({ parentName, childName }) => {
      if (!map[parentName]) {
        map[parentName] = { name: parentName, children: [] };
      }
      if (!map[childName]) {
        map[childName] = { name: childName, children: [] };
      }
      map[parentName].children.push(map[childName]);
    });

    Object.values(map).forEach(node => {
      if (!relations.some(relation => relation.childName === node.name)) {
        roots.push(node);
      }
    });

    return roots;
  };

  const tree = buildTree(queues);

  const renderList = (nodes) => (
    <ul key={nodes.name}>
      <li>{nodes.name}</li>
      {nodes.children && nodes.children.length > 0 && (
        <ul>
          {nodes.children.map((node) => renderList(node))}
        </ul>
      )}
    </ul>
  );

  return (
    <div>
    {tree.length > 0 ? (
      tree.map((queue) => renderList(queue))
    ) : (
      <p>No hay departamentos inferiores</p>
    )}
  </div>

  );
};

export default QueueList;