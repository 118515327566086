import React from 'react';
import { Box, IconButton } from '@material-ui/core';
import Cancel from '@material-ui/icons/Cancel';
import AttachFile from '@material-ui/icons/AttachFile';

const UploadImage = ({ preview, onRemove, onUpload }) => {
  return (
    <Box position="relative" mb={2}>
      {preview ? (
        <>
          <img
            src={preview}
            alt="mediaToUpload"
            style={{ width: "100%", maxHeight: "300px", objectFit: "cover" }}
          />
          <IconButton
            aria-label="close"
            onClick={onRemove}
            style={{ position: "absolute", top: 8, right: 8 }}
          >
            <Cancel />
          </IconButton>
        </>
      ) : (
        <>
          <input
            multiple
            type="file"
            id="upload-button"
            style={{display:"none"}}
            onChange={onUpload}
          />
          <label htmlFor="upload-button">
            <IconButton aria-label="upload" component="span">
              <AttachFile />
            </IconButton>
          </label>
        </>
      )}
    </Box>
  );
};

export default UploadImage;