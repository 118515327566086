import React, { useState, useEffect, useRef } from "react";
import * as Yup from "yup";
import { Formik, Form, Field } from "formik";
import EmogiPicker from "../EmogiPicker";
import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  InputAdornment,
  makeStyles,
  TextField,
  FormControl,
  List,
  ListItem
} from "@material-ui/core";
import { green } from "@material-ui/core/colors";
import { toast } from "react-toastify";
import { i18n } from "../../translate/i18n";
import api from "../../services/api";
import toastError from "../../errors/toastError";
import ColorPicker from "../ColorPicker";
import { Colorize } from "@material-ui/icons";
import QueueSelect from "../QueueSelect";
import UploadImage from "../UploadImage";
const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexWrap: "wrap",
  },
  textField: {
    marginRight: theme.spacing(1),
    flex: 1,
  },
  container: {
    display: "flex",
    flexWrap: "wrap",
  },
  btnWrapper: {
    position: "relative",
  },

  multFieldLine: {
    display: "flex",
    "& > *:not(:last-child)": {
      marginRight: theme.spacing(1),
    },
  },

  buttonProgress: {
    color: green[500],
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12,
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  colorAdorment: {
    width: 20,
    height: 20,
  },
  chips: {
    display: "flex",
    flexWrap: "wrap",
  },
  chip: {
    margin: 2,
  },
  emojiBox: {
    position: "absolute",
    bottom: 63,
    width: 40,
    borderTop: "1px solid #e8e8e8",
  },
}));

const QueueSchema = Yup.object().shape({
  name: Yup.string().min(2, "Too Short!").max(50, "Too Long!").required("Required"),
  color: Yup.string().min(3, "Too Short!").max(9, "Too Long!").required(),
  greetingMessage: Yup.string(),
  startWork: Yup.string(),
  endWork: Yup.string(),
  absenceMessage: Yup.string(),
});

const initialState = {
  name: "",
  color: "",
  greetingMessage: "",
  startWork: "",
  endWork: "",
  absenceMessage: "",
  orderQueue:"",
};

const QueueModal = ({ open, onClose, queueId }) => {
  const classes = useStyles();

  const [selectedQueueIds, setSelectedQueueIds] = useState([]);
  const [colorPickerModalOpen, setColorPickerModalOpen] = useState(false);
  const [queue, setQueue] = useState(initialState);

  //MEDIAS
  const [medias, setMedias] = useState([]);
  const [haveMedia, setHaveMedia] = useState("");
  const [previewUrls, setPreviewUrls] = useState([]);

  const greetingRef = useRef();
  const absenceRef = useRef();
  const startWorkRef = useRef();
  const endWorkRef = useRef();
  const orderQueueRef = useRef();

  useEffect(() => {
    const fetchSession = async () => {
      if (!queueId) return;
        try {
          const { data } = await api.get(`/queue/${queueId}`);
          setQueue(data);
          const children = data.relationsAsParent.map(relation => relation.child.id);
          setSelectedQueueIds(children);
          

        if(data.greetingMessageImage!=="" || data.greetingMessageImage !==null){
          const imageName = data.greetingMessageImage.split("/").pop();
         
            const { data: dataImage, headers } = await api.get("public/"+imageName, {
              responseType: "blob",
            });
            const url = window.URL.createObjectURL(
              new Blob([dataImage], { type: headers["content-type"] })
            );
          setHaveMedia(url);
          }
        
        } catch (err) {
          toastError(err);
        }
    };
    fetchSession();
  }, [queueId, open]);

  const handleSaveQueue = async (values) => {
    try {
      let imageUrl = null;

      if (medias.length > 0) {
        const formData = new FormData();
        medias.forEach((media) => {
          formData.append("medias", media);
        });
        try {
          const response = await api.post("/upload", formData);
          imageUrl = response.data.urls[0];
        } catch (error) {
          console.error("Error al subir la imagen:", error);
          return;
        }
      }
      const queueData = { ...values, childrens: selectedQueueIds, greetingMessageImage: imageUrl };
      if (queueId) {
        await api.put(`/queue/${queueId}`, queueData);
      } else {
        await api.post("/queue", queueData);
      }

      toast.success(`${i18n.t("queueModal.notification.title")}`);
      handleClose();
    } catch (err) {
      toastError(err);
    }
  };

  const handleClose = () => {
    onClose();
    setHaveMedia("");
    setMedias([]);
    setPreviewUrls([]);
    setQueue(initialState);
    setSelectedQueueIds([]);
  };

  const handleAddEmoji = (emoji) => {
    setQueue((prevState) => ({
      ...prevState,
      greetingMessage: prevState.greetingMessage + emoji.native,
    }));
  };

  const handleAddEmoji_absenceMessage = (emoji) => {
    setQueue((prevState) => ({
      ...prevState,
      absenceMessage: prevState.absenceMessage + emoji.native,
    }));
  };

  const handleRemoveMedia = () => {
    setMedias([]);
    setPreviewUrls([]);
    setHaveMedia(""); 
  };

  const handleChangeMedias = (e) => {
    if (!e.target.files) {
      return;
    }
    const files = Array.from(e.target.files);
    setMedias(files);
    
    const urls = files.map(file => URL.createObjectURL(file)); 
    setPreviewUrls(urls); 
  };

  return (
    <div className={classes.root}>
      <Dialog open={open} onClose={handleClose} scroll="paper"  maxWidth="md"
        fullWidth>
        <DialogTitle>
          {queueId
            ? `${i18n.t("queueModal.title.edit")}`
            : `${i18n.t("queueModal.title.add")}`}
        </DialogTitle>
        <Formik
          initialValues={queue}
          enableReinitialize={true}
          validationSchema={QueueSchema}
          onSubmit={(values, actions) => {
            setTimeout(() => {
              handleSaveQueue(values);
              actions.setSubmitting(false);
            }, 400);
          }}
        >
          {({ touched, errors, isSubmitting, values }) => (
            
            <Form>
              <DialogContent dividers>
                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <Field
                  as={TextField}
                  label={i18n.t("queueModal.form.name")}
                  autoFocus
                  name="name"
                  error={touched.name && Boolean(errors.name)}
                  helperText={touched.name && errors.name}
                  variant="outlined"
                  margin="dense"
                  className={classes.textField}
                />
                <Field
                  as={TextField}
                  label={i18n.t("queueModal.form.color")}
                  name="color"
                  id="color"
                  onFocus={() => {
                    setColorPickerModalOpen(true);
                    greetingRef.current.focus();
                  }}
                  className={classes.textField}
                  error={touched.color && Boolean(errors.color)}
                  helperText={touched.color && errors.color}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <div
                          style={{ backgroundColor: values.color }}
                          className={classes.colorAdorment}
                        ></div>
                      </InputAdornment>
                    ),
                    endAdornment: (
                      <IconButton
                        size="small"
                        color="default"
                        onClick={() => setColorPickerModalOpen(true)}
                      >
                        <Colorize />
                      </IconButton>
                    ),
                  }}
                  variant="outlined"
                  margin="dense"
                />
                <ColorPicker
                  open={colorPickerModalOpen}
                  handleClose={() => setColorPickerModalOpen(false)}
                  onChange={(color) => {
                    values.color = color;
                    setQueue(() => {
                      return { ...values, color };
                    });
                  }}
                />
                <Field
                  as={TextField}
                  type="number"
                  name="orderQueue"
                  inputRef={orderQueueRef}
                  margin="dense"
                  className={classes.textField}
                  label={i18n.t("queueModal.form.orderQueue")}
                  variant="outlined"
                />


                </div>
              
                <div className={classes.multFieldLine}>
                    <div id="upload">
                    {previewUrls.length > 0 ? (
                      <List>
                        {previewUrls.map((media, index) => {
                          return (
                            <ListItem key={index}>
                            <UploadImage preview={media} onRemove={handleRemoveMedia} onUpload={handleChangeMedias} />
                            </ListItem>
                          );
                        })}
                      </List>
                       ) : haveMedia ? (
                        <UploadImage preview={haveMedia} onRemove={handleRemoveMedia} onUpload={handleChangeMedias} />
                      ) : (
                        <>
                          {/* Input para seleccionar archivos */}
                        <UploadImage preview={""} onRemove={handleRemoveMedia} onUpload={handleChangeMedias} />
                        </>
                      )}
                    </div>
                    <FormControl variant="outlined" fullWidth>

                       <EmogiPicker
                    name="greetingMessage"
                    label={i18n.t("queueModal.form.greetingMessage")}
                    type="greetingMessage"
                    multiline
                    inputRef={greetingRef}
                    minRows={4}
                    fullWidth
                    variant="outlined"
                    margin="dense"
                    numberemogishow={16}
                    theme={"dark"}
                    showPreview={true}
                    showSkinTones={false}
                    handleAddEmoji={handleAddEmoji}
                    touched={touched}
                    errors={errors}
                  />
                    </FormControl>
                 
                </div>
                <form className={classes.container} noValidate>
                  <Field
                    as={TextField}
                    label={i18n.t("queueModal.form.startWork")}
                    type="time"
                    ampm={false}
                    defaultValue="08:00"
                    inputRef={startWorkRef}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    InputProps={{
                      step: 600, // 5 min
                    }}
                    fullWidth
                    name="startWork"
                    error={touched.startWork && Boolean(errors.startWork)}
                    helperText={touched.startWork && errors.startWork}
                    variant="outlined"
                    margin="dense"
                    className={classes.textField}
                  />
                  <Field
                    as={TextField}
                    label={i18n.t("queueModal.form.endWork")}
                    type="time"
                    ampm={false}
                    defaultValue="18:00"
                    inputRef={endWorkRef}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    inputProps={{
                      step: 600, // 5 min
                    }}
                    fullWidth
                    name="endWork"
                    error={touched.endWork && Boolean(errors.endWork)}
                    helperText={touched.endWork && errors.endWork}
                    variant="outlined"
                    margin="dense"
                    className={classes.textField}
                  />
                </form>
                <div>
                  <EmogiPicker
                    label={i18n.t("queueModal.form.absenceMessage")}
                    type="absenceMessage"
                    multiline
                    inputRef={absenceRef}
                    minRows={2}
                    fullWidth
                    name="absenceMessage"
                    error={
                      touched.absenceMessage && Boolean(errors.absenceMessage)
                    }
                    helperText={touched.absenceMessage && errors.absenceMessage}
                    variant="outlined"
                    margin="dense"
                    numberemogishow={16}
                    theme={"dark"}
                    showPreview={true}
                    showSkinTones={false}
                    handleAddEmoji={handleAddEmoji_absenceMessage}
                    touched={touched}
                    errors={errors}
                  />
                </div>
            
                  <QueueSelect
                    selectedQueueIds={selectedQueueIds}
                    onChange={(values) => setSelectedQueueIds(values)}
                    editingQueueId={queueId}
                    titleSelect={i18n.t("queueModal.form.queuesChildrens")}
                  />
               
              </DialogContent>
              <DialogActions>
                <Button
                  onClick={handleClose}
                  color="secondary"
                  disabled={isSubmitting}
                  variant="outlined"
                >
                  {i18n.t("queueModal.buttons.cancel")}
                </Button>
                <Button
                  type="submit"
                  color="primary"
                  disabled={isSubmitting}
                  variant="contained"
                  className={classes.btnWrapper}
                >
                  {queueId
                    ? `${i18n.t("queueModal.buttons.okEdit")}`
                    : `${i18n.t("queueModal.buttons.okAdd")}`}
                  {isSubmitting && (
                    <CircularProgress
                      size={24}
                      className={classes.buttonProgress}
                    />
                  )}
                </Button>
              </DialogActions>
            </Form>
          )}
        </Formik>
      </Dialog>
    </div>
  );
};

export default QueueModal;
