import React, { useContext, useState, useRef } from "react";
import { useHistory } from "react-router-dom";

import { makeStyles } from "@material-ui/core/styles";
import { IconButton, Tooltip, Button } from "@material-ui/core";
import {
  MoreVert,
  PictureAsPdf,
  DeleteForever,
  HourglassEmptyRounded,
  MoveToInbox,
} from "@material-ui/icons";

import { i18n } from "../../translate/i18n";
import api from "../../services/api";
import TicketOptionsMenu from "../TicketOptionsMenu";
import toastError from "../../errors/toastError";
import { AuthContext } from "../../context/Auth/AuthContext";
import { Can } from "../Can";
import useBitacora from "../../hooks/useBitacora";
import UticketDownload from "../UTicketDownload";
import ConfirmationModal from "../ConfirmationModal";
import AcceptTicketWithouSelectQueue from "../AcceptTicketWithoutQueueModal";

const useStyles = makeStyles((theme) => ({
  actionButtons: {
    marginRight: 6,
    flex: "none",
    alignSelf: "center",
    marginLeft: "auto",
    "& > *": {
      margin: theme.spacing(1),
    },
  },
}));

const TicketActionButtons = ({ ticket }) => {
  const classes = useStyles();
  const history = useHistory();
  const [anchorEl, setAnchorEl] = useState(null);
  const [loading, setLoading] = useState(false);
  const ticketOptionsMenuOpen = Boolean(anchorEl);
  const { user } = useContext(AuthContext);
  const [downloadTicketModalOpen, setDownloadTicketModalOpen] = useState(false);
  const isMounted = useRef(true);
  const [confirmationOpen, setConfirmationOpen] = useState(false);
  const [
    acceptTicketWithouSelectQueueOpen,
    setAcceptTicketWithouSelectQueueOpen,
  ] = useState(false);

  const handleOpenTicketOptionsMenu = (e) => {
    setAnchorEl(e.currentTarget);
  };

  const handleCloseTicketOptionsMenu = (e) => {
    setAnchorEl(null);
  };

  const handleOpenAcceptTicketWithouSelectQueue = () => {
    setAcceptTicketWithouSelectQueueOpen(true);
  };

  const { sendTransferMessage } = useBitacora();

  const handleDeleteTicket = async () => {
    try {
      await api.delete(`/tickets/${ticket.id}`);
    } catch (err) {
      toastError(err);
    }
  };

  const handleOpenConfirmationModal = (e) => {
    setConfirmationOpen(true);
  };

  const handleClosedConfirmationModal = (e) => {
    setConfirmationOpen(false);
  };

  const handleUpdateTicketStatus = async (e, status, userId) => {
    setLoading(true);
    try {
      await api.put(`/tickets/${ticket.id}`, {
        status: status,
        userId: userId || null,
        onClosed: 0,
      });
      setLoading(false);
      if (status === "open") {
        history.push(`/tickets/${ticket.id}`);
        await sendTransferMessage(
          ticket.id,
          7, // 5 significa transferido, 6 cerrado, 7 abierto revisar el hook para detalles.
          user?.name,
          null,
          "re-open"
        );
      } else {
        if (status !== "pending") {
          await sendTransferMessage(
            ticket.id,
            6, // 5 significa transferido, 6 cerrado, 7 abierto, 8 pendiente revisar el hook para detalles.
            user?.name,
            null,
            "resolve"
          );
        } else {
          await sendTransferMessage(
            ticket.id,
            8, // 5 significa transferido, 6 cerrado, 7 abierto, 8 pendiente revisar el hook para detalles.
            user?.name,
            null,
            "pending"
          );
        }
        history.push("/tickets");
      }
    } catch (err) {
      setLoading(false);
      toastError(err);
    }
  };
  const handleOpenDownloadModal = (e) => {
    setDownloadTicketModalOpen(true);
  };
  const handleCloseDownloadTicketModal = () => {
    if (isMounted.current) {
      setDownloadTicketModalOpen(false);
    }
  };

  return (
    <div className={classes.actionButtons}>
      {ticket.status === "closed" && (
        <>
          <ConfirmationModal
            title={`${i18n.t("ticketOptionsMenu.confirmationModal.title")}${
              ticket.id
            } ${i18n.t("ticketOptionsMenu.confirmationModal.titleFrom")} ${
              ticket.contact.name
            }?`}
            open={confirmationOpen}
            onClose={(e) => handleClosedConfirmationModal(e)}
            onConfirm={handleDeleteTicket}
          >
            {i18n.t("ticketOptionsMenu.confirmationModal.message")}
          </ConfirmationModal>
          <Tooltip placement ="bottom" title={i18n.t("messagesList.header.buttons.reopenHelp")}>
            <Button
              className={classes.bottomButton}
              color="primary"
              size="small"
              endIcon={<MoveToInbox />}
              variant="contained"
              loading={loading ? "true" : undefined}
              onClick={(e) => handleUpdateTicketStatus(e, "open", user?.id)}
            >
              {i18n.t("messagesList.header.buttons.reopen")}
            </Button>
          </Tooltip>
          <IconButton color="primary" onClick={handleOpenDownloadModal}>
            <PictureAsPdf />
          </IconButton>
          <UticketDownload
            modalOpen={downloadTicketModalOpen}
            onClose={handleCloseDownloadTicketModal}
            ticketid={ticket.id}
          />

          <Can
            role={user.profile}
            perform="ticket-options:deleteTicket"
            yes={() => (
              <IconButton
                className={classes.bottomButton}
                color="primary"
                onClick={(e) => handleOpenConfirmationModal(e)}
              >
                <DeleteForever />
              </IconButton>
            )}
          />
        </>
      )}
      {ticket.status === "open" && (
        <>
          <Tooltip placement ="bottom" title={i18n.t("messagesList.header.buttons.returnHelp")}>
            <Button
              loading={loading}
              endIcon={<HourglassEmptyRounded />}
              size="small"
              color="primary"
              variant="outlined"
              onClick={(e) => handleUpdateTicketStatus(e, "pending", null)}
            >
              {i18n.t("messagesList.header.buttons.return")}
            </Button>
          </Tooltip>
          <Tooltip placement ="bottom" title={i18n.t("messagesList.header.buttons.resolveHelp")}>
            <Button
              loading={loading}
              size="small"
              variant="contained"
              color="primary"
              onClick={(e) => handleUpdateTicketStatus(e, "closed", user?.id)}
            >
              {i18n.t("messagesList.header.buttons.resolve")}
            </Button>
          </Tooltip>

          <IconButton color="primary" onClick={handleOpenTicketOptionsMenu}>
            <MoreVert />
          </IconButton>
          <TicketOptionsMenu
            ticket={ticket}
            anchorEl={anchorEl}
            menuOpen={ticketOptionsMenuOpen}
            handleClose={handleCloseTicketOptionsMenu}
          />
        </>
      )}
      <Can
        role={user.profile}
        perform="drawer-admin-items:view"
        yes={() => (
          <>
            <AcceptTicketWithouSelectQueue
              modalOpen={acceptTicketWithouSelectQueueOpen}
              onClose={(e) => setAcceptTicketWithouSelectQueueOpen(false)}
              ticketId={ticket.id}
            />
            <div>
              {ticket.status === "pending"  && (
                  <Tooltip placement ="bottom" title={i18n.t("messagesList.header.buttons.acceptHelp")}>
                    <Button
                      className={classes.bottomButton}
                      color="primary"
                      size="small"
                      variant="contained"
                      loading={loading ? "true" : undefined}
                      onClick={
                        (e) => {
                          if(ticket.queue === null || ticket.queue === undefined){
                            handleOpenAcceptTicketWithouSelectQueue();
                          }
                          if(ticket.queue !== null){
                            handleUpdateTicketStatus(e, "open", user?.id)
                          }
                      }}
                    >
                      {i18n.t("messagesList.header.buttons.accept")}
                    </Button>
                  </Tooltip>
                )}
            </div>
          </>
        )}
      />
    </div>
  );
};

export default TicketActionButtons;
