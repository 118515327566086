import React, { useState } from "react";
import { Picker } from "emoji-mart"; 
import InsertEmoticonIcon from "@material-ui/icons/InsertEmoticon";
import "emoji-mart/css/emoji-mart.css";
import { Field } from "formik";
import { i18n } from "../../translate/i18n";
import {
  TextField,
  InputAdornment,
  IconButton,
  makeStyles,
  ClickAwayListener,
} from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  emojiBox: {
    position: "absolute",
    zIndex: 10,
    top: "10%",
    left: 0,
    width: "100%",
    backgroundColor: theme.palette.background.paper,
    border: "1px solid",
    borderColor: theme.palette.divider,
    borderRadius: theme.shape.borderRadius,
    boxShadow: theme.shadows[5],
  },
}));

const EmogiPicker = ({
  theme,
  showPreview,
  showSkinTones,
  handleAddEmoji,
  numberemogishow,
  name,
  values,
  touched,
  errors,
  isSubmitting,
  loading,
  ...rest
}) => {
  const classes = useStyles();
  const [showEmoji, setShowEmoji] = useState(false);
  const handleSelectEmoji = (e) => {
    if (handleAddEmoji) {
      handleAddEmoji(e);
    }
    setShowEmoji(false);
  };
  return (
    <div>
      <Field
        as={TextField}
        name={name}
        error={touched[name] && Boolean(errors[name])}
        helperText={touched[name] && errors[name]}
        {...rest}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <IconButton
                size="small"
                color="default"
                onClick={() => setShowEmoji(!showEmoji)}
              >
                <InsertEmoticonIcon />
              </IconButton>
            </InputAdornment>
          ),
        }}
      />
      {showEmoji ? (
        <div className={classes.emojiBox}>
          <ClickAwayListener onClickAway={(e) => setShowEmoji(true)}>
            <Picker
              perLine={numberemogishow} //numeros de emogis por fila
              theme={theme} //dark or light
              i18n={i18n}
              showPreview={showPreview} //true or false
              showSkinTones={showSkinTones} //true or false
              onSelect={handleSelectEmoji} //Funcion que agrega el emogi
            />
          </ClickAwayListener>
        </div>
      ) : null}
    </div>
  );
};

export default EmogiPicker;
