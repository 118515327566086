import React, { useState, useEffect, useRef } from "react";

import * as Yup from "yup";
import { Formik, Form, Field } from "formik";

import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  makeStyles,
  TextField,
} from "@material-ui/core";

import { green } from "@material-ui/core/colors";
import { toast } from "react-toastify";
import { i18n } from "../../translate/i18n";

import api from "../../services/api";
import toastError from "../../errors/toastError";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexWrap: "wrap",
  },
  textField: {
    marginRight: theme.spacing(1),
    flex: 1,
  },
  container: {
    display: "flex",
    flexWrap: "wrap",
  },
  btnWrapper: {
    position: "relative",
  },
  buttonProgress: {
    color: green[500],
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12,
  },

  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },

  colorAdorment: {
    width: 20,
    height: 20,
  },
}));

const MessageSchema = Yup.object().shape({
  desde: Yup.string()
    .min(2, i18n.t("scheduleModal.form.msjcorto"))
    .max(50, i18n.t("scheduleModal.form.msjlargo"))
    .required(i18n.t("scheduleModal.form.obligatorio")),
    hasta: Yup.string()
    .min(2, i18n.t("scheduleModal.form.msjcorto"))
    .max(50, i18n.t("scheduleModal.form.msjlargo"))
    .required(i18n.t("scheduleModal.form.obligatorio")),
    invalid: Yup.string(),
    subqueues: Yup.string(),
    back: Yup.string(),
    callmessage: Yup.string(),
});

const initialState = {
  desde: "",
  hasta: "",
  invalid: "",
  subqueues: "",
  back: "",
  callmessage: "",
};

const QueueModalSettings = ({ open, onClose, }) => {


  const classes = useStyles();

  const [messages, setMessages] = useState(initialState);

  const desde = useRef();
  const hasta = useRef();
  const invalid = useRef();
  const subqueues = useRef();
  const back = useRef();
  const callmessage = useRef();
  
  useEffect(() => {
    if (open) {
      (async () => {
        try {
          const settingKey = "automaticMessages";
          const { data } = await api.get(`/settings/${settingKey}`);
          console.log(data);
          //Se pasa a json para asignarlo al formik por medio de messages.
          const parsedData = typeof data.value === 'string' ? JSON.parse(data.value) : data.value;
          console.log(parsedData);
          setMessages(parsedData);    
        } catch (err) {
          toastError(err);
        }
      })();
    }
    return () => {
      if (!open) {
        setMessages(initialState);
      }
    };
  }, [open]);

  const handleClose = () => {
    onClose();
    setMessages(initialState);
  };

  const handleSaveSettings = async (values) => {
    try {
      const settingKey = "automaticMessages";
      //Se pasan los valores todos a cadena para ser guardados en la base de datos, por la regla del Sequalize.
      const valuesString = JSON.stringify(values);
      
      await api.put(`/settings/${settingKey}`, {
				value: valuesString,
			});
      toast.success(`${i18n.t("settings.settings.messagesQueues.notification")}`);
      handleClose();
    } catch (err) {
      toastError(err);
    }
  };

  return (
    <div className={classes.root}>
      <Dialog open={open} onClose={handleClose} scroll="paper">
        <DialogTitle>
          {i18n.t("settings.settings.messagesQueues.title")}
        </DialogTitle>
        <Formik
          initialValues={messages}
          enableReinitialize={true}
          validationSchema={MessageSchema}
          onSubmit={(values, actions) => {
            setTimeout(() => {
              handleSaveSettings(values);
              actions.setSubmitting(false);
            }, 400);
          }}
        >
          {({ touched, errors, isSubmitting, values }) => (
            <Form className={classes.container}>
              <DialogContent dividers>
                <div className={classes.multFieldLine}>
                  <Field
                    as={TextField}
                    //label={i18n.t("settings.settings.messagesQueues.desde")}
                    autoFocus
                    name="desde"
                    error={touched.name && Boolean(errors.name)}
                    variant="standard"
                    margin="dense"
                    className={classes.textField}
                    helperText={i18n.t("settings.settings.messagesQueues.desde_helper")}
                    inputRef={desde}
                    
                  />

                  <Field
                    as={TextField}
                    //label={i18n.t("settings.settings.messagesQueues.hasta")}
                    autoFocus
                    name="hasta"
                    error={touched.name && Boolean(errors.name)}
                    variant="standard"
                    margin="dense"
                    className={classes.textField}
                    helperText={i18n.t("settings.settings.messagesQueues.hasta_helper")}
                    inputRef={hasta}
                  />
                </div>
                <div className={classes.multFieldLine}>
                  <Field
                    as={TextField}
                    //label={i18n.t("settings.settings.messagesQueues.invalid")}
                    autoFocus
                    name="invalid"
                    error={touched.invalid && Boolean(errors.invalid)}
                    helperText={i18n.t("settings.settings.messagesQueues.invalid_helper")}
                    variant="standard"
                    margin="dense"
                    className={classes.textField}
                    fullWidth
                    inputRef={invalid}
                  />
                </div>

                <div className={classes.multFieldLine}>
                  <Field
                    as={TextField}
                    //label={i18n.t("settings.settings.messagesQueues.subqueues")}
                    autoFocus
                    name="subqueues"
                    error={touched.subqueues && Boolean(errors.subqueues)}
                    helperText={i18n.t("settings.settings.messagesQueues.subqueues_helper")}                    
                    variant="standard"
                    margin="dense"
                    className={classes.textField}
                    fullWidth
                    inputRef={subqueues}
                  />
                </div>
                <div className={classes.multFieldLine}>
                  <Field
                    as={TextField}
                    //label={i18n.t("settings.settings.messagesQueues.back")}
                    autoFocus
                    name="back"
                    error={touched.back && Boolean(errors.back)}
                    helperText={i18n.t("settings.settings.messagesQueues.back_helper")}   
                    variant="standard"
                    margin="dense"
                    className={classes.textField}
                    fullWidth
                    inputRef={back}
                  />
                </div>
                <div className={classes.multFieldLine}>
                  <Field
                    as={TextField}
                    //label={i18n.t("settings.settings.messagesQueues.callmessage")}
                    autoFocus
                    name="callmessage"
                    error={touched.callmessage && Boolean(errors.callmessage)}
                    helperText={i18n.t("settings.settings.messagesQueues.callmessage_helper")}   
                    variant="standard"
                    margin="dense"
                    className={classes.textField}
                    fullWidth
                    inputRef={callmessage}
                  />
                </div>
              </DialogContent>
              <DialogActions>
                <Button
                  onClick={handleClose}
                  color="secondary"
                  disabled={isSubmitting}
                  variant="outlined"
                >
                  {i18n.t("queueModal.buttons.cancel")}
                </Button>
                <Button
                  type="submit"
                  color="primary"
                  disabled={isSubmitting}
                  variant="contained"
                  className={classes.btnWrapper}
                >
                  {i18n.t("settings.settings.messagesQueues.save")}
                  {isSubmitting && (
                    <CircularProgress
                      size={24}
                      className={classes.buttonProgress}
                    />
                  )}
                </Button>
              </DialogActions>
            </Form>
          )}
        </Formik>
      </Dialog>
    </div>
  );
};

export default QueueModalSettings;
