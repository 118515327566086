import React, { useState, useEffect } from "react";
import * as Yup from "yup";
import { Formik, Form, Field } from "formik";
import { toast } from "react-toastify";

import { makeStyles } from "@material-ui/core/styles";
import { green } from "@material-ui/core/colors";
import EmogiPicker from "../EmogiPicker";
import UploadImage from "../UploadImage";
import Autocomplete, {
  createFilterOptions,
} from "@material-ui/lab/Autocomplete";

import {
  Dialog,
  DialogContent,
  DialogTitle,
  Button,
  DialogActions,
  CircularProgress,
  TextField,
  Switch,
  FormControlLabel,
  Tabs,
  Tab,
  Select,
  Grid,
  InputLabel,
  FormHelperText,
  FormControl,
  MenuItem,
  List,
  ListItem
} from "@material-ui/core";

import Alert from "@material-ui/lab/Alert";
import api from "../../services/api";
import { i18n } from "../../translate/i18n";
import toastError from "../../errors/toastError";
import QueueSelect from "../QueueSelect";
import TabPanel from "../TabPanel";


const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexWrap: "wrap",
  },

  multFieldLine: {
    display: "flex",
    "& > *:not(:last-child)": {
      marginRight: theme.spacing(1),
    },
  },

  btnWrapper: {
    position: "relative",
  },

  buttonProgress: {
    color: green[500],
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12,
  },
  settingOption: {
    marginLeft: "auto",
  },
  marginTop: {
    marginTop: 20,
  },
  alert: {
    marginBottom: 20,
  },
  uploadInput: {
    display: "none",
  },
}));

const SessionSchema = Yup.object().shape({
  name: Yup.string()
    .min(2, "Too Short!")
    .max(50, "Too Long!")
    .required("Required"),
});

const WhatsAppModal = ({ open, onClose, whatsAppId }) => {
  const classes = useStyles();

  const [tab, setTab] = useState("general");

  const handleChangeTab = (e, newValue) => {
    setTab(newValue);
  };

  const filterOptions = createFilterOptions({
    trim: true,
  });

  const initialState = {
    name: "",
    greetingMessage: "",
    farewellMessage: "",
    isDefault: false,
    isDisplay: false,

    userMessageActive: false,
    noUserMessage: "",
    secondNoUserMessage: "",
    autoCloseTime: "",
    waitCloseTime: "",

    asesorMessageActive: false,
    noAsesorMessage: "",
    secondNoAsesorMessage: "",
    autoCloseTimeAsesor: "",
    waitCloseTimeAsesor: "",
    asesorId: "",
    greetingMessageImage:""
  };
  const initialUser = {
    id: "",
    name: "",
  };

  const [whatsApp, setWhatsApp] = useState(initialState);
  const [selectedQueueIds, setSelectedQueueIds] = useState([]);

  //SELECT USER
  const [currentUser, setCurrentUser] = useState(initialUser);
  const [options, setOptions] = useState([]);
  const [searchParam, setSearchParam] = useState("");
  const [loading, setLoading] = useState(false);
  
  //MEDIAS
  const [medias, setMedias] = useState([]);
  const [haveMedia, setHaveMedia] = useState("");
  const [previewUrls, setPreviewUrls] = useState([]);

  //CARGA DE ASISTENTES PARA EL AUTOCOMPLETE
  useEffect(() => {
    if (!open || searchParam.length < 2) {
      setLoading(false);
      return;
    }
    setLoading(true);
    const delayDebounceFn = setTimeout(() => {
      const fetchUsers = async () => {
        try {
          const { data } = await api.get("/users/", {
            params: { searchParam },
          });
          setOptions(data.users);
          setLoading(false);
        } catch (err) {
          setLoading(false);
          toastError(err);
        }
      };

      fetchUsers();
    }, 500);
    return () => clearTimeout(delayDebounceFn);
  }, [searchParam, open]);

  //Panel de control de las pestañas

  //Apertura de la modal
  useEffect(() => {

    const fetchSession = async () => {
      if (!whatsAppId) return;

      try {
        const { data } = await api.get(`whatsapp/${whatsAppId}`);
        setWhatsApp(data);
        
        const { data: currentUserArray } = await api.get("/users/" + data.asesorId);

        const currentUser = {
          id: currentUserArray.id,
          name: currentUserArray.name,
        };
      
        setCurrentUser(currentUser);

        const whatsQueueIds = data.queues?.map((queue) => queue.id);
        setSelectedQueueIds(whatsQueueIds);

        if(data.greetingMessageImage!=="" || data.greetingMessageImage !==null){
          const imageName = data.greetingMessageImage.split("/").pop();
          console.log(imageName);
         
            const { data: dataImage, headers } = await api.get("public/"+imageName, {
              responseType: "blob",
            });
            const url = window.URL.createObjectURL(
              new Blob([dataImage], { type: headers["content-type"] })
            );
          setHaveMedia(url);
        
          }
      } catch (err) {
        toastError(err);
      }
    };
    fetchSession();
  }, [whatsAppId]);
  
  const handleSaveWhatsApp = async (values) => {
    let imageUrl = null;

    if (medias.length > 0 ) {
      const formData = new FormData();
      medias.forEach((media) => {
        formData.append("medias", media);
      });
      try {
        const response = await api.post('/upload', formData);
        imageUrl = response.data.urls[0];
      } catch (error) {
        console.error('Error al subir la imagen:', error);
        return;
      }
    }
    //const { noAsesorMessage, ...rest } = values;
    const whatsappData = { ...values, queueIds: selectedQueueIds, greetingMessageImage:imageUrl };
    try {
      if (whatsAppId) {
        await api.put(`/whatsapp/${whatsAppId}`, whatsappData);
      } else {
        await api.post("/whatsapp", whatsappData);
      }
      toast.success(i18n.t("whatsappModal.success"));
      handleClose();
    } catch (err) {
      toastError(err);
    }
  };

  const handleClose = () => {
    onClose();
    setHaveMedia("");
    setMedias([]);
    setPreviewUrls([]);
    setWhatsApp(initialState);
  };

  const handleChangeMedias = (e) => {
    if (!e.target.files) {
      return;
    }
    const files = Array.from(e.target.files);
    setMedias(files);
    
    const urls = files.map(file => URL.createObjectURL(file)); 
    setPreviewUrls(urls); 
  };

  return (
    
    <div className={classes.root}>
      <Dialog
        open={open}
        onClose={handleClose}
        maxWidth="md"
        fullWidth
        scroll="paper"
      >
        <DialogTitle>
          {whatsAppId
            ? i18n.t("whatsappModal.title.edit")
            : i18n.t("whatsappModal.title.add")}
        </DialogTitle>
        <Formik
          initialValues={whatsApp}
          enableReinitialize={true}
          validationSchema={SessionSchema}
          onSubmit={(values, actions) => {
            setTimeout(() => {
              handleSaveWhatsApp(values);
              actions.setSubmitting(false);
            }, 400);
          }}
        >
          {({ values, touched, errors, isSubmitting, setFieldValue }) => {
            
            const handleAddEmoji_noAsesorMessage = (emoji) => {
              setFieldValue(
                "noAsesorMessage",
                values.noAsesorMessage + emoji.native
              );
            };

            const handleAddEmoji_secondNoAsesorMessage = (emoji) => {
              setFieldValue(
                "noAsesorMessage",
                values.secondNoAsesorMessage + emoji.native
              );
            };
            const handleAddEmoji_noUserMessage = (emoji) => {
              setFieldValue(
                "noUserMessage",
                values.noUserMessage + emoji.native
              );
            };

            const handleAddEmoji_secondNoUserMessage = (emoji) => {
              setFieldValue(
                "secondNoUserMessage",
                values.secondNoUserMessage + emoji.native
              );
            };

            const handleAddEmoji = (emoji) => {
              setWhatsApp((prevState) => ({
                ...prevState,
                greetingMessage: prevState.greetingMessage + emoji.native,
              }));
            };

            const handleAddEmoji_farewellMessage = (emoji) => {
              setWhatsApp((prevState) => ({
                ...prevState,
                farewellMessage: prevState.farewellMessage + emoji.native,
              }));
            };

            const handleRemoveMedia = () => {
              setMedias([]);
              setPreviewUrls([]);
              setHaveMedia(""); 
            };

            return (
              <Form>
                <DialogContent dividers>
                  <Tabs
                    value={tab}
                    onChange={handleChangeTab}
                    aria-label="tabs"
                    centered
                  >
                    <Tab
                      value={"general"}
                      label={i18n.t("whatsappModal.form.tab_general")}
                    />
                    <Tab
                      value={"client"}
                      label={i18n.t("whatsappModal.form.tab_client")}
                    />
                    <Tab
                      value={"asesor"}
                      label={i18n.t("whatsappModal.form.tab_asesor")}
                    />
                  </Tabs>
                  <TabPanel value={tab} name={"general"}>
                    <Field
                      as={TextField}
                      label={i18n.t("whatsappModal.form.name")}
                      autoFocus
                      name="name"
                      error={touched.name && Boolean(errors.name)}
                      helperText={touched.name && errors.name}
                      variant="outlined"
                      margin="dense"
                      className={classes.textField}
                    />
                    <FormControlLabel
                      control={
                        <Field
                          as={Switch}
                          color="primary"
                          name="isDefault"
                          checked={values.isDefault}
                        />
                      }
                      label={i18n.t("whatsappModal.form.default")}
                    />
                    <FormControlLabel
                      control={
                        <Field
                          as={Switch}
                          color="primary"
                          name="isDisplay"
                          checked={values.isDisplay}
                        />
                      }
                      label={i18n.t("whatsappModal.form.display")}
                    />

                    <div className={classes.multFieldLine}>
                    <div id="upload">
                    {previewUrls.length > 0 ? (
                      <List>
                        {previewUrls.map((media, index) => {
                          return (
                            <ListItem key={index}>
                            <UploadImage preview={media} onRemove={handleRemoveMedia} onUpload={handleChangeMedias} />
                            </ListItem>
                          );
                        })}
                      </List>
                       ) : haveMedia ? (
                        console.log(haveMedia),
                        <UploadImage preview={haveMedia} onRemove={handleRemoveMedia} onUpload={handleChangeMedias} />
                      ) : (
                        <>
                          {/* Input para seleccionar archivos */}
                        <UploadImage preview={""} onRemove={handleRemoveMedia} onUpload={handleChangeMedias} />
                        </>
                      )}
                    </div>
                    <FormControl variant="outlined" fullWidth>
                    <EmogiPicker
                      label={i18n.t("queueModal.form.greetingMessage")}
                      type="greetingMessage"
                      multiline
                      minRows={5}
                      value={
                        values.greetingMessage === null
                          ? ""
                          : values.greetingMessage
                      }
                      fullWidth
                      name="greetingMessage"
                      error={
                        touched.greetingMessage &&
                        Boolean(errors.greetingMessage)
                      }
                      helperText={
                        touched.greetingMessage && errors.greetingMessage
                      }
                      variant="outlined"
                      margin="dense"
                      numberemogishow={16}
                      theme={"dark"}
                      showPreview={true}
                      showSkinTones={false}
                      handleAddEmoji={handleAddEmoji}
                      touched={touched}
                      errors={errors}
                    />
                    </FormControl>
                    </div>

                    <EmogiPicker
                      label={i18n.t("whatsappModal.form.farewellMessage")}
                      type="farewellMessage"
                      multiline
                      minRows={5}
                      value={
                        values.farewellMessage === null
                          ? ""
                          : values.farewellMessage
                      }
                      fullWidth
                      name="farewellMessage"
                      error={
                        touched.farewellMessage &&
                        Boolean(errors.farewellMessage)
                      }
                      helperText={
                        touched.farewellMessage && errors.farewellMessage
                      }
                      variant="outlined"
                      margin="dense"
                      numberemogishow={16}
                      theme={"dark"}
                      showPreview={true}
                      showSkinTones={false}
                      handleAddEmoji={handleAddEmoji_farewellMessage}
                      touched={touched}
                      errors={errors}
                    />

                    <QueueSelect
                      selectedQueueIds={selectedQueueIds}
                      onChange={(selectedIds) =>
                        setSelectedQueueIds(selectedIds)
                      }
                    />
                  </TabPanel>
                  <TabPanel value={tab} name={"client"}>
                    <div className={classes.alert}>
                      <Alert variant="outlined" severity="info">
                        {i18n.t("whatsappModal.form.info_client")}
                      </Alert>
                    </div>
                    <div className={classes.multFieldLine}>
                      <FormControlLabel
                        control={
                          <Field
                            as={Switch}
                            color="primary"
                            name="userMessageActive"
                            checked={values.userMessageActive}
                            onChange={(event) => {
                              setFieldValue(
                                "userMessageActive",
                                event.target.checked
                              );
                            }}
                          />
                        }
                        label={i18n.t("whatsappModal.form.clientAusente")}
                      />
                    </div>
                    <div>
                      <EmogiPicker
                        label={i18n.t("whatsappModal.form.primerMensajeClient")}
                        type="noUserMessage"
                        multiline
                        minRows={5}
                        value={
                          values.noUserMessage === null
                            ? ""
                            : values.noUserMessage
                        }
                        fullWidth
                        name="noUserMessage"
                        error={
                          touched.noUserMessage && Boolean(errors.noUserMessage)
                        }
                        helperText={
                          touched.noUserMessage && errors.noUserMessage
                        }
                        variant="outlined"
                        margin="dense"
                        numberemogishow={16}
                        theme={"dark"}
                        showPreview={true}
                        showSkinTones={false}
                        handleAddEmoji={handleAddEmoji_noUserMessage}
                        touched={touched}
                        errors={errors}
                      />
                    </div>
                    <div>
                      <EmogiPicker
                        label={i18n.t(
                          "whatsappModal.form.mensajeDespedidaClient"
                        )}
                        type="secondNoUserMessage"
                        multiline
                        minRows={5}
                        value={
                          values.secondNoUserMessage === null
                            ? ""
                            : values.secondNoUserMessage
                        }
                        fullWidth
                        name="secondNoUserMessage"
                        error={
                          touched.secondNoUserMessage &&
                          Boolean(errors.secondNoUserMessage)
                        }
                        helperText={
                          touched.secondNoUserMessage &&
                          errors.secondNoUserMessage
                        }
                        variant="outlined"
                        margin="dense"
                        numberemogishow={16}
                        theme={"dark"}
                        showPreview={true}
                        showSkinTones={false}
                        handleAddEmoji={handleAddEmoji_secondNoUserMessage}
                        touched={touched}
                        errors={errors}
                      />
                    </div>
                    <div className={classes.multFieldLine}>
                      <Grid container spacing={2}>
                        <Grid item xs={12} sm={6}>
                          <FormControl className={classes.formControl}>
                            <InputLabel id="autoCloseTime-label-helper">
                              {i18n.t("whatsappModal.form.warningTime")}{" "}
                            </InputLabel>
                            <Select
                              id="autoCloseTime"
                              name="autoCloseTime"
                              value={
                                values.autoCloseTime === null
                                  ? ""
                                  : values.autoCloseTime
                              }
                              onChange={(e) => {
                                const autoCloseTimeS = e.target.value;
                                setFieldValue("autoCloseTime", autoCloseTimeS);
                              }}
                            >
                              <MenuItem value="">
                                <em>Ninguno</em>
                              </MenuItem>
                              <MenuItem value="60">
                                {i18n.t(
                                  "settings.settings.timeCreateNewTicket.options.60"
                                )}
                              </MenuItem>
                              <MenuItem value="300">
                                {i18n.t(
                                  "settings.settings.timeCreateNewTicket.options.300"
                                )}
                              </MenuItem>
                              <MenuItem value="600">
                                {i18n.t(
                                  "settings.settings.timeCreateNewTicket.options.600"
                                )}
                              </MenuItem>
                              <MenuItem value="900">
                                {i18n.t(
                                  "settings.settings.timeCreateNewTicket.options.900"
                                )}
                              </MenuItem>
                              <MenuItem value="1200">
                                {i18n.t(
                                  "settings.settings.timeCreateNewTicket.options.1200"
                                )}
                              </MenuItem>
                              <MenuItem value="1500">
                                {i18n.t(
                                  "settings.settings.timeCreateNewTicket.options.1500"
                                )}
                              </MenuItem>
                              <MenuItem value="1800">
                                {i18n.t(
                                  "settings.settings.timeCreateNewTicket.options.1800"
                                )}
                              </MenuItem>
                            </Select>
                            <FormHelperText>
                              {i18n.t("whatsappModal.form.warningTimeHelper")}
                            </FormHelperText>
                          </FormControl>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                          <FormControl className={classes.formControl}>
                            <InputLabel id="waitCloseTime-label-helper">
                              {i18n.t("whatsappModal.form.closeTime")}{" "}
                            </InputLabel>
                            <Select
                              id="waitCloseTime"
                              name="waitCloseTime"
                              value={
                                values.waitCloseTime === null
                                  ? ""
                                  : values.waitCloseTime
                              }
                              onChange={(e) => {
                                const waitCloseTimeS = e.target.value;
                                setFieldValue("waitCloseTime", waitCloseTimeS);
                              }}
                            >
                              <MenuItem value="">
                                <em>Ninguno</em>
                              </MenuItem>
                              <MenuItem value="60">
                                {i18n.t(
                                  "settings.settings.timeCreateNewTicket.options.60"
                                )}
                              </MenuItem>
                              <MenuItem value="300">
                                {i18n.t(
                                  "settings.settings.timeCreateNewTicket.options.300"
                                )}
                              </MenuItem>
                              <MenuItem value="600">
                                {i18n.t(
                                  "settings.settings.timeCreateNewTicket.options.600"
                                )}
                              </MenuItem>
                              <MenuItem value="900">
                                {i18n.t(
                                  "settings.settings.timeCreateNewTicket.options.900"
                                )}
                              </MenuItem>
                              <MenuItem value="1200">
                                {i18n.t(
                                  "settings.settings.timeCreateNewTicket.options.1200"
                                )}
                              </MenuItem>
                              <MenuItem value="1500">
                                {i18n.t(
                                  "settings.settings.timeCreateNewTicket.options.1500"
                                )}
                              </MenuItem>
                              <MenuItem value="1800">
                                {i18n.t(
                                  "settings.settings.timeCreateNewTicket.options.1800"
                                )}
                              </MenuItem>
                            </Select>
                            <FormHelperText>
                              {i18n.t("whatsappModal.form.closeTimeHelper")}
                            </FormHelperText>
                          </FormControl>
                        </Grid>
                      </Grid>
                    </div>
                  </TabPanel>
                  <TabPanel value={tab} name={"asesor"}>
                    <div className={classes.alert}>
                      <Alert variant="outlined" severity="info">
                        {i18n.t("whatsappModal.form.info_asesor")}
                      </Alert>
                    </div>
                    <div className={classes.multFieldLine}>
                      <FormControlLabel
                        control={
                          <Field
                            as={Switch}
                            color="primary"
                            name="asesorMessageActive"
                            checked={values.asesorMessageActive}
                            onChange={(event) => {
                              setFieldValue(
                                "asesorMessageActive",
                                event.target.checked
                              );
                            }}
                          />
                        }
                        label={i18n.t("whatsappModal.form.asesorAusente")}
                      />
                      <FormControl variant="outlined" fullWidth>
                        <Autocomplete
                          name="asesorId"
                          fullWidth
                          getOptionLabel={(option) => `${option.name}`}
                          onChange={(e, newValue) => {
                            const userId = newValue ? newValue.id : "";
                            setFieldValue("asesorId", userId);
                          }}
                          options={options}
                          filterOptions={filterOptions}
                          freeSolo
                          autoHighlight
                          noOptionsText={i18n.t(
                            "transferTicketModal.noOptions"
                          )}
                          loading={loading}
                          value={currentUser}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label={i18n.t("whatsappModal.form.asesorId")}
                              variant="outlined"
                              onChange={(e) => setSearchParam(e.target.value)}
                              InputProps={{
                                ...params.InputProps,
                                endAdornment: (
                                  <React.Fragment>
                                    {loading ? (
                                      <CircularProgress
                                        color="inherit"
                                        size={20}
                                      />
                                    ) : null}
                                    {params.InputProps.endAdornment}
                                  </React.Fragment>
                                ),
                              }}
                            />
                          )}
                        />
                      </FormControl>
                    </div>
                    <div>
                      <EmogiPicker
                        label={i18n.t("whatsappModal.form.primerMensajeAsesor")}
                        type="noAsesorMessage"
                        multiline
                        minRows={5}
                        value={
                          values.noAsesorMessage === null
                            ? ""
                            : values.noAsesorMessage
                        }
                        fullWidth
                        name="noAsesorMessage"
                        error={
                          touched.noAsesorMessage &&
                          Boolean(errors.noAsesorMessage)
                        }
                        helperText={
                          touched.noAsesorMessage && errors.noAsesorMessage
                        }
                        variant="outlined"
                        margin="dense"
                        numberemogishow={16}
                        theme={"dark"}
                        showPreview={true}
                        showSkinTones={false}
                        handleAddEmoji={handleAddEmoji_noAsesorMessage}
                        touched={touched}
                        errors={errors}
                      />
                    </div>
                    <div>
                      <EmogiPicker
                        label={i18n.t(
                          "whatsappModal.form.mensajeDespedidaAsesor"
                        )}
                        type="secondNoAsesorMessage"
                        multiline
                        minRows={5}
                        value={
                          values.secondNoAsesorMessage === null
                            ? ""
                            : values.secondNoAsesorMessage
                        }
                        fullWidth
                        name="secondNoAsesorMessage"
                        error={
                          touched.secondNoAsesorMessage &&
                          Boolean(errors.secondNoAsesorMessage)
                        }
                        helperText={
                          touched.secondNoAsesorMessage &&
                          errors.secondNoAsesorMessage
                        }
                        variant="outlined"
                        margin="dense"
                        numberemogishow={16}
                        theme={"dark"}
                        showPreview={true}
                        showSkinTones={false}
                        handleAddEmoji={handleAddEmoji_secondNoAsesorMessage}
                        touched={touched}
                        errors={errors}
                      />
                    </div>
                    <div className={classes.multFieldLine}>
                      <Grid container spacing={2}>
                        {" "}
                        <Grid item xs={12} sm={6}>
                          <FormControl className={classes.formControl}>
                            <InputLabel id="autoCloseTimeAsesor-helper-label">
                              {i18n.t("whatsappModal.form.warningTime")}
                            </InputLabel>
                            <Select
                              name="autoCloseTimeAsesor"
                              id="autoCloseTimeAsesor"
                              value={
                                values.autoCloseTimeAsesor === null
                                  ? ""
                                  : values.autoCloseTimeAsesor
                              }
                              onChange={(e) => {
                                const autoCloseTimeAsesorS = e.target.value;
                                setFieldValue("autoCloseTimeAsesor",autoCloseTimeAsesorS);
                              }}
                            >
                              <MenuItem value="">
                                <em>Ninguno</em>
                              </MenuItem>
                              <MenuItem value="60">
                                {i18n.t(
                                  "settings.settings.timeCreateNewTicket.options.60"
                                )}
                              </MenuItem>
                              <MenuItem value="300">
                                {i18n.t(
                                  "settings.settings.timeCreateNewTicket.options.300"
                                )}
                              </MenuItem>
                              <MenuItem value="600">
                                {i18n.t(
                                  "settings.settings.timeCreateNewTicket.options.600"
                                )}
                              </MenuItem>
                              <MenuItem value="900">
                                {i18n.t(
                                  "settings.settings.timeCreateNewTicket.options.900"
                                )}
                              </MenuItem>
                              <MenuItem value="1200">
                                {i18n.t(
                                  "settings.settings.timeCreateNewTicket.options.1200"
                                )}
                              </MenuItem>
                              <MenuItem value="1500">
                                {i18n.t(
                                  "settings.settings.timeCreateNewTicket.options.1500"
                                )}
                              </MenuItem>
                              <MenuItem value="1800">
                                {i18n.t(
                                  "settings.settings.timeCreateNewTicket.options.1800"
                                )}
                              </MenuItem>
                            </Select>
                            <FormHelperText>
                              {i18n.t("whatsappModal.form.warningTimeHelper")}
                            </FormHelperText>
                          </FormControl>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                          <FormControl className={classes.formControl}>
                            <InputLabel id="waitCloseTimeAsesor-helper-label">
                              {i18n.t("whatsappModal.form.closeTime")}
                            </InputLabel>
                            <Select
                              name="waitCloseTimeAsesor"
                              id="waitCloseTimeAsesor"
                              value={
                                values.waitCloseTimeAsesor === null
                                  ? ""
                                  : values.waitCloseTimeAsesor
                              }
                              onChange={(e) => {
                                const waitCloseTimeAsesorSelect =
                                  e.target.value;
                                setFieldValue(
                                  "waitCloseTimeAsesor",
                                  waitCloseTimeAsesorSelect
                                );
                              }}
                            >
                              <MenuItem value="">
                                <em>Ninguno</em>
                              </MenuItem>
                              <MenuItem value="60">
                                {i18n.t(
                                  "settings.settings.timeCreateNewTicket.options.60"
                                )}
                              </MenuItem>
                              <MenuItem value="300">
                                {i18n.t(
                                  "settings.settings.timeCreateNewTicket.options.300"
                                )}
                              </MenuItem>
                              <MenuItem value="600">
                                {i18n.t(
                                  "settings.settings.timeCreateNewTicket.options.600"
                                )}
                              </MenuItem>
                              <MenuItem value="900">
                                {i18n.t(
                                  "settings.settings.timeCreateNewTicket.options.900"
                                )}
                              </MenuItem>
                              <MenuItem value="1200">
                                {i18n.t(
                                  "settings.settings.timeCreateNewTicket.options.1200"
                                )}
                              </MenuItem>
                              <MenuItem value="1500">
                                {i18n.t(
                                  "settings.settings.timeCreateNewTicket.options.1500"
                                )}
                              </MenuItem>
                              <MenuItem value="1800">
                                {i18n.t(
                                  "settings.settings.timeCreateNewTicket.options.1800"
                                )}
                              </MenuItem>
                            </Select>
                            <FormHelperText>
                              {i18n.t("whatsappModal.form.closeTimeHelper")}
                            </FormHelperText>
                          </FormControl>
                        </Grid>
                      </Grid>
                    </div>
                  </TabPanel>
                </DialogContent>
                <DialogActions>
                  <Button
                    onClick={handleClose}
                    color="secondary"
                    disabled={isSubmitting}
                    variant="outlined"
                  >
                    {i18n.t("whatsappModal.buttons.cancel")}
                  </Button>
                  <Button
                    type="submit"
                    color="primary"
                    disabled={isSubmitting}
                    variant="contained"
                    className={classes.btnWrapper}
                  >
                    {whatsAppId
                      ? i18n.t("whatsappModal.buttons.okEdit")
                      : i18n.t("whatsappModal.buttons.okAdd")}
                    {isSubmitting && (
                      <CircularProgress
                        size={24}
                        className={classes.buttonProgress}
                      />
                    )}
                  </Button>
                </DialogActions>
              </Form>
            );
          }}
        </Formik>
      </Dialog>
    </div>
  );
};

export default React.memo(WhatsAppModal);
