const messages = {
  es: {
    translations: {
      signup: {
        title: "Registrarse",
        toasts: {
          success: "¡El asistente se creó correctamente! ¡Inicia sesión!",
          fail: "Error al crear el asistente. Verifique los datos ingresados.",
        },
        form: {
          name: "Nombre",
          email: "Correo electrónico",
          password: "Contraseña",
        },
        buttons: {
          submit: "Registrarse",
          login: "¿Ya tienes una cuenta? ¡Inicia sesión!",
        },
      },
      Login: {
        title: "Iniciar sesión ahora",
        form: {
          email: "Ingrese correo electrónico",
          password: "Ingrese su contraseña",
        },
        buttons: {
          submit: "Iniciar sesión",
          register: "¿No tienes una cuenta? ¡Regístrate!",
        },
      },
      auth: {
        toasts: {
          success: "¡El inicio de sesión fue exitoso!",
        },
      },
      messageVariablesPicker: {
        label: "Variables disponibles",
        vars: {
          contactName: "Nombre",
          user: "Asistente",
          greeting: "Saludo",
          protocolNumber: "Protocolo",
          date: "Fecha",
          hour: "Hora",
          ticket_id: "Ticked ID",
          queue: "Departamento",
          connection: "Conexión",
        },
      },
      dashboard: {
        charts: {
          perDay: {
            title: "Mensajes de hoy:",
          },
        },
        messages: {
          inAttendance: {
            title: "En asistencia",
          },
          waiting: {
            title: "En espera",
          },
          closed: {
            title: "Resueltos",
          },
        },
      },
      connections: {
        title: "Conexiones",
        toasts: {
          deleted: "¡La conexión de WhatsApp se eliminó correctamente!",
          resolve: "¡El solucionador de problemas se ha ejecutado correctamente!",
        },
        confirmationModal: {
          deleteTitle: "Eliminar",
          deleteMessage: "¿Estás seguro? Esta acción no se puede revertir.",
          disconnectTitle: "Desconectar",
          disconnectMessage:"¿Estás seguro? Deberás escanear el código QR nuevamente.",
          resolveTitle: "Ejecutar el solucionador",
          resolveMessage:"Se resolvera el problema que tienes al establecer una conexion, si esto no resuelve el problema, contacta con soporte tecnico.",
        },
        buttons: {
          add: "Agregar WhatsApp",
          restart: "Reiniciar",
          disconnect: "Desconectar",
          tryAgain: "Intentar de nuevo",
          qrcode: "CÓDIGO QR",
          newQr: "Nuevo CÓDIGO QR",
          connecting: "Conectando",
        },
        toolTips: {
          disconnected: {
            title: "Error al iniciar sesión en WhatsApp",
            content:
              "Asegúrese de que su teléfono celular esté conectado a Internet y vuelva a intentarlo, o solicite un nuevo Código QR",
          },
          qrcode: {
            title: "Esperando para leer el código QR",
            content:
              "Haga clic en el botón 'CÓDIGO QR' y escanee el código QR con su teléfono celular para iniciar sesión.",
          },
          connected: {
            title: "¡Conexión establecida!",
          },
          timeout: {
            title: "Se perdió la conexión con el celular",
            content:
              "Asegúrese de que su móvil esté conectado a Internet y WhatsApp esté abierto, o haga clic en el botón 'Desconectar' para obtener un nuevo código QR",
          },
        },
        table: {
          id: "ID de instancia",
          name: "Nombre",
          number: "Número",
          status: "Estado",
          lastUpdate: "Última actualización",
          default: "Predeterminado",
          actions: "Acciones",
          session: "Sesión",
        },
      },
      whatsappModal: {
        title: {
          add: "Agregar WhatsApp",
          edit: "Editar WhatsApp",
        },
        form: {
          name: "Nombre",
          default: "Predeterminado",
          display: "Mostrar hora del Departamento",
          farewellMessage: "Mensaje de despedida",

          asesorAusente: "Activar mensaje de asesor ausente",
          primerMensajeAsesor: "Mensaje de aviso de asesor ausente",
          mensajeDespedidaAsesor: "Mensaje de despedida de asesor ausente",

          clientAusente: "Activar mensaje para cliente ausente",
          primerMensajeClient: "Mensaje de aviso para cliente ausente",
          mensajeDespedidaClient: "Mensaje de despedida para cliente ausente",
          
          tab_general:"General",
          tab_client:"Inactividad de cliente",
          tab_asesor:"Inactividad de asesor",

          info_client:"Mensajes que se enviaran automaticamente, despues de trascurrido los tiempos de inactividad por parte del cliente.",
          info_asesor:"Mensaje que se enviaran automaticamente, despues de trascurrido los tiempos de inactividad por parte del asesor.",

          asesorId:"Asesor al que se le asiganaran tickets cerrados",
          warningTime:"Tiempo para aviso",
          closeTime:"Tiempo para cerrar la conversacion",
          warningTimeHelper:"Tiempo que debe trascurrir de inactividad para enviar el mensaje de aviso.",
          closeTimeHelper:"El tiempo que trascurre para cerrar la conversacion luego del aviso."
        },
        buttons: {
          okAdd: "Agregar",
          okEdit: "Guardar",
          cancel: "Cancelar",
        },
        success: "WhatsApp se guardó correctamente.",
      },
      qrCode: {
        message: "Lee el QRCode para iniciar sesión",
      },
      contacts: {
        title: "Contactos",
        toasts: {
          deleted: "¡Contacto eliminado con éxito!",
          deletedAll: "¡Todos los contactos se eliminaron con éxito!",
        },
        searchPlaceholder: "Buscar...",
        confirmationModal: {
          deleteTitle: "Eliminar",
          deleteAllTitle: "Borrar todo",
          importTitle: "Importar contactos",
          deleteMessage:
            "¿Está seguro de que desea eliminar este contacto? Se perderán todos los tickets relacionados.",
          deleteAllMessage:
            "¿Está seguro de que desea eliminar todos los contactos? Se perderán todos los tickets relacionados.",
          importMessage: "¿Quieres importar todos los contactos del teléfono?",
        },
        buttons: {
          import: "Importar contactos",
          add: "Agregar contacto",
          export: "Exportar contactos",
          delete: "Eliminar todos los contactos",
        },
        table: {
          name: "Nombre",
          whatsapp: "WhatsApp",
          email: "Correo electrónico",
          actions: "Acciones",
        },
      },
      contactModal: {
        title: {
          add: "Agregar contacto",
          edit: "Editar contacto",
        },
        form: {
          mainInfo: "Datos de contacto",
          extraInfo: "Información adicional",
          name: "Nombre",
          number: "Número de WhatsApp",
          email: "Correo electrónico",
          extraName: "Nombre de campo",
          extraValue: "Valor",
        },
        buttons: {
          addExtraInfo: "Añadir información",
          okAdd: "Agregar",
          okEdit: "Guardar",
          cancel: "Cancelar",
        },
        success: "Contacto guardado con exito.",
      },
      quickAnswersModal: {
        title: {
          add: "Agregar respuesta rápida",
          edit: "Editar respuesta rápida",
        },
        form: {
          shortcut: "Atajo",
          message: "Respuesta rápida",
        },
        buttons: {
          okAdd: "Agregar",
          okEdit: "Guardar",
          cancel: "Cancelar",
        },
        success: "Respuesta rápida guardada con éxito.",
      },
      tags: {
        title: "Etiquetas",
        table: {
          name: "Etiquetas",
          color: "Color",
          contacts: "Contactos",
          actions: "Acción",
        },
        toasts: {
          deleted: "¡Etiqueta eliminada con éxito!",
          deletedAll: "¡Todas las etiquetas se eliminaron con éxito!",
        },
        buttons: {
          add: "Agregar",
          deleteAll: "Borrar todo",
        },
        confirmationModal: {
          deleteTitle: "Eliminar ",
          deleteAllTitle: "Borrar todo",
          deleteMessage: "¿Está seguro de que desea eliminar esta etiqueta?",
          deleteAllMessage:
            "¿Está seguro de que desea eliminar todas las etiquetas?",
        },
      },
      tagModal: {
        title: {
          add: "Agregar etiqueta",
          edit: "Editar etiqueta",
        },
        buttons: {
          okAdd: "Guardar",
          okEdit: "Editar",
          cancel: "Cancelar",
        },
        form: {
          name: "Nombre de la etiqueta",
          color: "Color de la etiqueta",
        },
        success: "¡Etiqueta guardada con éxito!",
      },
      queueModal: {
        title: {
          add: "Agregar Departamento",
          edit: "Editar Departamento",
          three: "Arbol de departamentos",
        },
        notification: {
          title: "¡Departamento guardado con éxito!",
        },
        form: {
          name: "Nombre",
          color: "Color",
          greetingMessage: "Mensaje de saludo",
          startWork: "Apertura",
          endWork: "Cierre",
          absenceMessage: "Mensaje de ausencia",
          parent: "Departamentos",
          queuesSuperior:"D.Superior",
          queuesChildrens:"Departamentos hijos",
          orderQueue:"Orden de aparicion",
          orderQueueHelper:"Orden de aparicion en la lista"
        },
        buttons: {
          okAdd: "Agregar",
          okEdit: "Guardar",
          cancel: "Cancelar",
          close:"Cerrar",
        },
      },
      userModal: {
        title: {
          add: "Agregar asistente",
          edit: "Editar asistente",
        },
        form: {
          name: "Nombre",
          email: "Correo electrónico",
          password: "Contraseña",
          profile: "Perfil",
          admin: "Administrador",
          whatsapp: "Conexión estándar",
          user: "Asistente",
          startWork: "Inicio",
          endWork: "Finalizar",
          isTricked: "Ver contactos",
          enabled: "Habilitado",
          disabled: "Deshabilitado",
        },
        buttons: {
          okAdd: "Agregar",
          okEdit: "Guardar",
          cancel: "Cancelar",
        },
        success: "El asistente se guardó con éxito.",
      },
      chat: {
        noTicketMessage: "Seleccione una conversacion para comenzar a chatear.",
      },
      uploads: {
        titles: {
          titleUploadMsgDragDrop:
            "⬇️ ARRASTRA Y SUELTA LOS ARCHIVOS EN EL CAMPO DE ABAJO ⬇️",
          titleFileList: "Lista de archivos",
        },
      },
      ticketsManager: {
        buttons: {
          newTicket: "Nuevo",
        },
      },
      ticketsQueueSelect: {
        placeholder: "Departamentos",
      },
      tickets: {
        toasts: {
          deleted: "El ticket en el que estabas ha sido eliminado.",
        },
        notification: {
          message: "Mensaje de",
        },
        tabs: {
          open: { title: "Mios" },
          closed: { title: "Resuelto" },
          search: { title: "Buscar" },
        },
        search: {
          placeholder: "Buscar conversaciones y mensajes",
        },
        buttons: {
          showAll: "Todos",
        },
      },
      scheduleModal: {
        title: {
          add: "Nuevo recordatorio",
          edit: "Editar Recordatorio",
        },
        form: {
          body: "Mensaje",
          contact: "Contacto",
          sendAt: "Fecha",
          queueId: "Departamento",
          obligatorio:"Obligatorio",
          conection:"Conexion",
          msjcorto:"El mensaje es muy corto, intente escribir uno más largo.",
          msjlargo:"El mensaje es muy largo."
        },
        buttons: {
          okAdd: "Agregar",
          okEdit: "Guardar",
          cancel: "Cancelar",
        },
        success: "Recordatorio agregado con exito",
        edit: "Recordatorio editado correctamente",
      },
      transferTicketModal: {
        title: "Transferir",
        fieldLabel: "Busca el asistente",
        fieldConnectionLabel: "Transferir a conexión",
        fieldQueueLabel: "Transferir a departamento",
        fieldConnectionPlaceholder: "Seleccione una conexión",
        noOptions: "No se encontró ningún asistente con ese nombre",
        buttons: {
          ok: "Transferir",
          cancel: "Cancelar",
        },
      },
      uTicketDownload: {
        title: "Descargando el Ticket #",
        buttons: {
          loading: "Cargando mensajes...",
          download: "Descargar PDF",
        },
      },
      uNotesModal: {
        title: "Agregar una nota programada",
        messageLabel:"Mensaje al operador/asistente",
        dateLabel:"Fecha",
        timeLabel:"Hora",
        buttons: {
          save: "Guardar",
        },
      },
      ticketsList: {
        pendingHeader: "En espera",
        assignedHeader: "De:",
        noTicketsTitle: "¡No hay conversaciones!",
        noTicketsMessage:
          "No se encontraron conversaciones con este estado o término de búsqueda",
        connectionTitle: "Conexión actualmente en uso.",
        items: {
          queueless: "Sin departamento",
          accept: "Aceptar",
          spy: "Espía",
          close: "Resolver",
          reopen: "Reabrir",
          return: "Mover a espera",
          connection: "Conexión",
          user: "Asistente",
          queue: "Departamento",
          none: "Ninguno",
          tags: "Etiquetas",
        },
        buttons: {
          accept: "Responder",
          acceptBeforeBot: "Aceptar",
          start: "empezar",
          cancel: "Cancelar",
        },
        acceptModal: {
          title: "Asigne un departamento",
          queue: "Seleccione un Departamento",
        },
      },
      newTicketModal: {
        title: "Nueva conversacion",
        fieldLabel: "Escriba, para buscar contacto",
        add: "Agregar",
        buttons: {
          ok: "Guardar",
          cancel: "Cancelar",
        },
      },
      newTicketModalContactPage: {
        title: "Nueva conversacion",
        queue: "Seleccione un Departamento",
        fieldLabel: "Escriba, para buscar contacto",
        add: "Agregar",
        buttons: {
          ok: "Guardar",
          cancel: "Cancelar",
        },
      },
      mainDrawer: {
        listItems: {
          dashboard: "Tablero",
          connections: "Conexiones",
          tickets: "Conversaciones",
          contacts: "Contactos",
          quickAnswers: "Respuestas rápidas",
          tags: "Etiquetas",
          queues: "Departamentos",
          administration: "Administración",
          users: "Asistentes",
          settings: "Configuración",
          sendMsg: "Enviando Mensajes",
          sendMedia: "Enviando medios",
          integrations: "Integraciones",
          apiUse: "Uso de la API",
          apidocs: "Documentación",
          tutorials: "Tutoriales y soporte",
          apititle: "Ajustes",
          schedules: "Recordatorios",
          api: "API",
          apikey: "Clave API",
          token: "Token",
        },
        appBar: {
          message: {
            hi: "Hola",
            text: "bienvenido a",
          },
          user: {
            profile: "Perfil",
            logout: "Cerrar sesión",
          },
          buttons: {
            help: "Ver Guias y Ayuda",
            profile: "Tu perfil"
          }
        },
      },
      notifications: {
        noTickets: "Sin notificaciones.",
      },
      queues: {
        title: "Departamentos",
        notifications: {
          queueDeleted: "El departamento ha sido eliminado.",
        },
        table: {
          id: "ID",
          name: "Nombre",
          color: "Color",
          greeting: "Mensaje de saludo",
          actions: "Acciones",
          startWork: "Apertura",
          endWork: "Cierre",
          orden: "Orden",
        },
        buttons: {
          add: "Agregar",
        },
        confirmationModal: {
          deleteTitle: "Eliminar",
          deleteMessage:
            "¿Estás seguro? ¡Esta acción no se puede revertir! Los tickets de este departamento seguirán existiendo, pero ya no tendrán ningún departamento asignado.",
        },
      },
      queueSelect: {
        inputLabel: "Departamentos",
      },
      quickAnswers: {
        title: "Respuestas rápidas",
        table: {
          shortcut: "Atajo",
          message: "Respuesta rápida",
          actions: "Acciones",
        },
        buttons: {
          add: "Agregar respuesta rápida",
          deletedAll: "Eliminar todas las respuestas rápidas",
        },
        toasts: {
          deleted: "Respuesta rápida eliminada con éxito.",
          deletedAll: "Todas las respuestas rápidas eliminadas.",
        },
        searchPlaceholder: "Buscar...",
        confirmationModal: {
          deleteTitle:
            "¿Está seguro de que desea eliminar esta respuesta rápida: ",
          deletedAll:
            "¿Está seguro de que desea eliminar todas las respuestas rápidas?",
          deleteMessage: "Esta acción no se puede revertir.",
          deleteAllMessage: "Esta acción no se puede revertir.",
        },
      },
      users: {
        title: "Asistentes",
        table: {
          id: "ID",
          name: "Nombre",
          email: "Correo electrónico",
          profile: "Perfil",
          tricked: "Ver contactos",
          whatsapp: "Conexión estándar",
          startWork: "Hora de inicio",
          endWork: "Tiempo de finalización",
          acciones: "Acciones",
        },
        buttons: {
          add: "Agregar asistente",
        },
        toasts: {
          deleted: "Asistente eliminado con éxito.",
          updated: "Asistente actualizado con éxito.",
        },
        confirmationModal: {
          deleteTitle: "Eliminar",
          deleteMessage:
            "Se perderán todos los datos del asistente. Los tickets abiertos para este asistente se moverán a espera.",
        },
      },
      schedules: {
        title: "Recordatorios",
        confirmationModal: {
          deleteTitle: "Esta seguro de eliminar este recordatorio?",
          deleteMessage: "Esta accion no puede ser revertida.",
        },
        table: {
          client: "Cliente",
          body: "Recordatorio",
          date: "Momento del envio",
          status: "Estado",
        },
        buttons: {
          add: "Nuevo recordatorio",
        },
        toasts: {
          deleted: "Recordatorio eliminado con exito.",
        },
      },
      company: {
        success: "Los datos de la empresa se guardaron con éxito.",
        title: "Datos de la empresa",
        info: "Información",
        name: "Nombre",
        url: "Sitio web",
      },
      integrations: {
        success: "Integracion guardada con exito.",
        title: "Integraciones",
        integrations: {
          openai: {
            title: "OpenAI",
            organization: "Organization ID",
            apikey: "KEY",
          },
          n8n: {
            title: "N8N",
            urlApiN8N: "URL API N8N",
          },
        },
      },
      settings: {
        success: "Configuración guardada con éxito.",
        title: "Configuración",
        settings: {
          userCreation: {
            name: "Creación de asistente",
            note: "Permitir la creación de asistentes",
            options: {
              enabled: "Habilitado",
              disabled: "Deshabilitado",
            },
          },
          messagesQueues:{
            title:"Configurar textos en mensajes automaticos",
            name:"Editar mensajes automaticos",
            note:"Mensajes que se muestran en respuestas automaticas",
            save:"Guardar",
            desde:"Desde",
            desde_helper:"Palabra 'Desde' de la frase: Desde 12:00 hasta 14:00 mostrada en los mensajes automaticos de departamentos.",
            hasta:"Hasta",
            hasta_helper:"Palabra 'Hasta' en la frase: Desde 12:00 hasta 14:00 mostrada en los mensajes automaticos de departamentos.",
            invalid:"Seleccion invalida",
            invalid_helper:"Mensaje que se mostrará al usuario al elegir un numero de departamento que no existe.",
            subqueues:"Titulo para mensaje que antecede el sub-departamento en la lista de selecciones",
            subqueues_helper:"Ejemplo: Soporte - 'Tiene los siguientes sub-departamentos.'",
            back:"Regresar al menu principal",
            back_helper:"Mensaje que indica al usuario que puede regresar al menu principal.",
            callmessage:"Este numero de whatsapp no acepta llamadas de voz/video",
            callmessage_helper:"Mensaje que se envia al usuario cuando intenta llamar al numero de whatsapp, cuando la opcion de llamada esta desactivada en las configuraciones.",
            automessage_helper:"Mensaje que se envia tras el tiempo de inactividad de un ticket.",
            notification:"Mensajes automaticos guardados con exito",
          },
          allTicket: {
            name: "Todo el mundo puede ver el ticket sin departamento",
            note: "Active esta función para que todos los usuarios vean los chats que no tengan un departamento",
            options: {
              enabled: "Habilitado",
              disabled: "Deshabilitado",
            },
          },
          CheckMsgIsGroup: {
            name: "Ignorar mensajes de grupo",
            note: "Si lo desactivas, recibirás mensajes de grupos.",
            options: {
              enabled: "Habilitado",
              disabled: "Deshabilitado",
            },
          },
          autoClose: {
            name: "Mensaje tras inactividad",
            note: "Selecciona el tiempo en el que se enviara un mensaje de inactividad, indicando que el ticket se cerrara automaticamente.",
            options: {
              enabled: "Habilitado",
              disabled: "Deshabilitado",
            },
          },
          call: {
            name: "Aceptar llamadas",
            note: "Si está deshabilitado, el cliente recibirá un mensaje de que no acepta llamadas de voz/video",
            options: {
              enabled: "Habilitado",
              disabled: "Deshabilitado",
            },
          },
          sideMenu: {
            name: "Menú lateral abierto",
            note: "Habilita que el menu lateral siempre este abierto.",
            options: {
              enabled: "Abierto",
              disabled: "Cerrado",
            },
          },
          closeTicketApi: {
            name: "Cerrar ticket enviado API",
            note: "Cierra automáticamente el ticket cuando se envía a través de API",
            options: {
              enabled: "Habilitado",
              disabled: "Deshabilitado",
            },
          },
          darkMode: {
            name: "Habilitar modo oscuro",
            note: "Cambiar entre el modo claro y el modo oscuro",
            options: {
              enabled: "Habilitado",
              disabled: "Deshabilitado",
            },
          },
          ASC: {
            name: "Orden ascendente para los Chats",
            note: "Cuando está habilitado, se ordenará de forma ascendente (ASC), al deshabilitarlo se ordenará de forma descendente (DESC)",
            options: {
              enabled: "Habilitado",
              disabled: "Deshabilitado",
            },
          },
          created: {
            name: "Orden de conversaciones por creacion",
            note: "Cuando se activa, se ordenará por fecha de creación, al desactivar se ordenará por fecha de actualización",
            options: {
              enabled: "Habilitado",
              disabled: "Deshabilitado",
            },
          },
          timeCreateNewTicket: {
            name: "Crear nuevo ticket después de",
            note: "Seleccione el tiempo que tardará en abrir un nuevo ticket si el cliente lo contacta nuevamente",
            options: {
              10: "10 segundos",
              30: "30 segundos",
              60: "1 minuto",
              300: "5 minutos",
              600:"10 minutos",
              900:"15 minutos",
              1200:"20 minutos",
              1500:"25 minutos",
              1800: "30 minutos",
              3600: "1 hora",
              7200: "2 horas",
              21600: "6 horas",
              43200: "12 horas",
              86400: "24 horas",
              604800: "7 días",
              1296000: "15 días",
              2592000: "30 días",
            },
          },
          waitCloseTime:{
            name: "Tiempo antes de cerrar la conversacion",
          },
          timeCronJob: {
            name: "Buscar recordatorios cada",
            note: "Seleccione el tiempo en el que el sistema buscara notas guardadas",
            options: {
              15: "15 min",
              30: "30 min",
              45: "45 min",
              60: "60 min",
            },
          },
          timeClose: {
            name: "Cerrar tickets automaticamente cada",
            note: "Seleccione el tiempo que debe transcurrir para cerrar una conversacion inactiva",
            options: {
              15: "15 min",
              30: "30 min",
              45: "45 min",
              60: "60 min",
            },
          },
        },
      },
      messagesList: {
        header: {
          assignedTo: "De:",
          buttons: {
            return: "Mover a Espera",
            resolve: "Resolver",
            reopen: "Reabrir",
            accept: "Aceptar",
            acceptHelp: "Aceptar esta conversacion",
            returnHelp: "Enviar la conversacion a ESPERA",
            resolveHelp: "Marcar conversacion como resuelta",
            reopenHelp: "Re-Abrir la conversacion, sera asignada a ti ",
          },
        },
      },
      messagesInput: {
        placeholderOpen: "Ingrese un mensaje",
        placeholderClosed:
          "Reabrir o aceptar este ticket para enviar un mensaje.",
        signMessage: "Firmar",
      },
      contactDrawer: {
        header: "Datos de contacto",
        buttons: {
          edit: "Editar contacto",
        },
        extraInfo: "Otra información",
      },
      copyToClipboard: {
        copy: "Copiar",
        copied: "Copiado",
      },
      ticketOptionsMenu: {
        delete: "Eliminar",
        transfer: "Transferir",
        schedules:"Recordatorio",
        confirmationModal: {
          title: "Eliminar el ticket ",
          titleFrom: "del contacto ",
          message:
            "¡Atención! Todos los mensajes relacionados con la conversacion se perderán.",
        },
        buttons: {
          delete: "Eliminar",
          cancel: "Cancelar",
        },
      },
      confirmationModal: {
        buttons: {
          confirm: "Está bien",
          cancel: "Cancelar",
        },
      },
      messageOptionsMenu: {
        delete: "Eliminar",
        reply: "Responder",
        confirmationModal: {
          title: "¿Eliminar mensaje?",
          message: "Esta acción no se puede revertir.",
        },
      },
      backendErrors: {
        ERR_NO_OTHER_WHATSAPP:
          "Debe haber al menos un WhatsApp predeterminado.",
        ERR_NO_DEF_WAPP_FOUND:
          "No se encontró WhatsApp predeterminado. Verifique la página de conexiones.",
        ERR_WAPP_NOT_INITIALIZED:
          "Esta sesión de WhatsApp no ​​fue inicializada. Por favor revisa la página de conexiones.",
        ERR_WAPP_CHECK_CONTACT:
          "No se pudo verificar el contacto de WhatsApp. Verifique la página de conexiones.",
        ERR_WAPP_INVALID_CONTACT: "Este no es un número de Whatsapp válido.",
        ERR_WAPP_DOWNLOAD_MEDIA:
          "No se pueden descargar medios de WhatsApp. Verifique la página de conexiones",
        ERR_INVALID_CREDENTIALS: "Error de autenticación. Vuelva a intentarlo",
        ERR_SENDING_WAPP_MSG:
          "Error al enviar el mensaje de WhatsApp. Verificar página de conexiones.",
        ERR_DELETE_WAPP_MSG: "No se pudo eliminar el mensaje de WhatsApp.",
        ERR_OTHER_OPEN_TICKET: "Ya hay un ticket abierto para este contacto.",
        ERR_SESSION_EXPIRED: "Sesión caducada. Inicie sesión.",
        ERR_USER_CREATION_DISABLED:
          "La creación de asistentes ha sido deshabilitada por el administrador.",
        ERR_NO_PERMISSION: "No tienes permiso para acceder a este recurso.",
        ERR_DUPLICATED_CONTACT: "Ya existe un contacto con este número.",
        ERR_NO_SETTING_FOUND: "No se encontraron configuraciones con esta ID.",
        ERR_NO_CONTACT_FOUND: "No se encontró ningún contacto con esta ID.",
        ERR_NO_TICKET_FOUND: "No se encontraron boletos con esta ID.",
        ERR_NO_USER_FOUND: "No se encontró ningún asistente con esta ID.",
        ERR_NO_WAPP_FOUND: "No se encontró WhatsApp con este ID.",
        ERR_CREATING_MESSAGE: "Error al crear el mensaje en la base de datos.",
        ERR_CREATING_TICKET: "Error al crear el ticket en la base de datos.",
        ERR_FETCH_WAPP_MSG:
          "Error al obtener el mensaje en WhatsApp, puede ser demasiado antiguo.",
        ERR_QUEUE_COLOR_ALREADY_EXISTS:
          "Este color ya está en uso, elige otro.",
        ERR_WAPP_GREETING_REQUIRED:
          "El mensaje de saludo es obligatorio cuando hay más de un Departamento.",
        ERR_USER_CREATION_COUNT:
          "Límite de asistentes alcanzado, comuníquese con soporte para cambiar",
        ERR_CONNECTION_CREATION_COUNT:
          "Límite de conexión alcanzado, comuníquese con soporte para cambiar.",
        ERR_NO_TAG_FOUND: "Etiqueta no encontrada.",
        ERR_OPEN_USER_TICKET: "Ya existe un ticket para este contacto con ",
        ERR_NONE_USER_TICKET: "Ya existe un ticket para este contacto.",
        ERR_QUEUE_INVALID_ORDER: "El orden debe tener al menos un valor.",
        ERR_QUEUE_ORDER_ALREADY_EXISTS: "Ese Orden ya lo tiene otro departamento.",
      },
    },
  },
};

export { messages };
