import React, { useState, useEffect } from "react";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Button,
  CircularProgress,
} from "@material-ui/core";
import api from "../../services/api";
import toastError from "../../errors/toastError";
import QueueList from "./index";
import { i18n } from "../../translate/i18n";

const QueueListModal = ({ open, onClose }) => {
  const [queues, setQueues] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (open) {
      setLoading(true);
      (async () => {
        try {
          const { data } = await api.get("/queueRelations");
          setQueues(data);
          setLoading(false);
        } catch (err) {
          toastError(err);
          setLoading(false);
        }
      })();
    }
  }, [open]);

  const handleClose = () => {
    onClose();
  };

  return (
    <Dialog open={open} onClose={handleClose} scroll="paper">
      <DialogTitle>{i18n.t("queueModal.title.three")}</DialogTitle>
      <DialogContent dividers>
        {loading ? (
          <CircularProgress />
        ) : (
          <QueueList queues={queues} />
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary">
          {i18n.t("queueModal.buttons.close")}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default QueueListModal;